import Rest from '../Rest'

const templateExpositionInfo = {
  'reset_password' : {
    'name': 'Nome do Usuário que solicitou a recuperação de senha.',
    'password': 'Senha do Usuário que solicitou a recuperação de senha.',
    'link': 'Link de mudança de senha do usuário'
  },
  'welcome': {
    'name': 'Nome do Usuário que acaba de ser criado.',
    'password': 'Senha do Usuário criado.',
    'institution': 'Valor possívelmente vazio, nome da instituição em que o Usuário foi criado.'
  },
  'questionnaire_result': {
    'email': 'Email do usuário que respondeu o questionário.',
    'name': 'Nome do usuário que respondeu o questionário.',
    'questionnaire_name': 'Nome do Questionário',
    'questionnaire_id': 'Código do questionário',
    'result_url': 'Link que leva o usuário à tela de resultados do questionário'
  },
  'questionnaire_publication': {
    'questionnaire_name': 'Nome do Questionário',
    'short_url': 'Link que leva o usuário à tela de responder o questionário.',
    'email': 'O email destinatário.',
    'institution': 'Possivelmente vazio, nome da instituição.',
    'student_id': 'Possivelmente vazio, código do registro de estudante do usuário caso o email especificado possua usuário.',
    'name': 'Possivelmente vazio, nome do usuário caso o email especificado possua usuário cadastrado no sistema.',
    'password': 'Senha do Usuário. Obs. A senha é informada somente quando efetuar o cadastro via planilha do participante. Caso já tenha se cadastrado, não é enviado no e-mail.',
  },
  'class_invite': {
    'class_title': 'Nome da turma',
    'link': 'Link para abrir a tela de aceitar o convite de turma.'
  }
}

export default class EmailTemplateService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/email_templates'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getEmailTypes() {
    return this.get(`/types`).then( data => {
      return data.map(type => {
        const templateType = {...type,
          subject: JSON.parse(type.subject),
          content: JSON.parse(type.content)
        }
        templateType.info = (type.name in templateExpositionInfo) ? templateExpositionInfo[type.name] : null
        return templateType;
      })
    })
  }
}
