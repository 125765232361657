var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.typeUnavailable
        ? _c(
            "div",
            { staticClass: "flex justify-items-center" },
            [
              _c(
                "vs-alert",
                {
                  staticClass: "mt-5 text-warning mb-4",
                  attrs: {
                    title: _vm.$t("alerta"),
                    color: "rgb(231, 154, 23)",
                    active: "true",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "por-enquanto-nao-e-possivel-customizar-esse-tipo-de-email"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vx-card",
        {
          staticClass: "mb-4",
          attrs: {
            title:
              _vm.id === null
                ? _vm.$t("email_template_create")
                : _vm.$t("email_template_edit"),
          },
        },
        [
          _c(
            "div",
            { staticClass: "vx-row mb-6 gap-2 items-end justify-start" },
            [
              _c(
                "div",
                { staticClass: "vx-col flex-grow" },
                [
                  _c(
                    "vs-select",
                    {
                      staticClass: "w-full",
                      attrs: { label: _vm.$t("tipo") },
                      on: { input: _vm.typeChanged },
                      model: {
                        value: _vm.emailTemplateTypeId,
                        callback: function ($$v) {
                          _vm.emailTemplateTypeId = $$v
                        },
                        expression: "emailTemplateTypeId",
                      },
                    },
                    [
                      _c("vs-select-item", {
                        attrs: { value: null, text: "Nenhum" },
                      }),
                      _vm._l(_vm.emailTypesSelectItems, function (type) {
                        return _c("vs-select-item", {
                          key: type.value,
                          attrs: { value: type.value, text: type.text },
                        })
                      }),
                    ],
                    2
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("template_type_id"),
                          expression: "errors.has('template_type_id')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("template_type_id")))]
                  ),
                ],
                1
              ),
              _vm.selectedType
                ? _c(
                    "div",
                    { staticClass: "vx-col pb-2" },
                    [
                      _c(
                        "vs-checkbox",
                        {
                          model: {
                            value: _vm.active,
                            callback: function ($$v) {
                              _vm.active = $$v
                            },
                            expression: "active",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("ativo")) + " ")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("active"),
                              expression: "errors.has('active')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("active")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm.selectedType
            ? _c("div", { staticClass: "vx-row mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: _vm.$t("assunto") },
                      model: {
                        value: _vm.subject,
                        callback: function ($$v) {
                          _vm.subject = $$v
                        },
                        expression: "subject",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("subject"),
                            expression: "errors.has('subject')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("subject")))]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.selectedType
            ? _c("div", { staticClass: "vx-row mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("label", { staticClass: "text-sm pl-1" }, [
                      _vm._v(_vm._s(_vm.$t("conteudo"))),
                    ]),
                    _c("complex-editor", {
                      attrs: { editor_data: _vm.content },
                      on: {
                        "update:editor_data": function ($event) {
                          _vm.content = $event
                        },
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("content"),
                            expression: "errors.has('content')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("content")))]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "float-right",
                    attrs: { color: "primary", type: "border" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v(_vm._s(_vm.$t("action.cancel")))]
                ),
                _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "email_templates.create",
                        expression: "'email_templates.create'",
                      },
                    ],
                    staticClass: "float-right mr-2",
                    attrs: { disabled: !_vm.validateForm },
                    on: { click: _vm.create },
                  },
                  [_vm._v(_vm._s(_vm.$t("action.save")))]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.typeInformation
        ? _c(
            "vx-card",
            {
              staticClass: "w-full mb-4",
              attrs: {
                title: _vm.$t("informacoes-do-tipo-de-modelo-selecionado"),
              },
            },
            [
              _c(
                "vs-list",
                _vm._l(_vm.typeInformation, function (information, key) {
                  return _c("vs-list-item", {
                    key: key,
                    attrs: { title: "{" + key + "}", subtitle: information },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }