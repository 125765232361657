<template>
  <div>
    <div class="flex justify-items-center" v-if="typeUnavailable">
      <vs-alert
        :title="$t('alerta')"
        color="rgb(231, 154, 23)"
        active="true"
        class="mt-5 text-warning mb-4">
          {{ $t('por-enquanto-nao-e-possivel-customizar-esse-tipo-de-email') }}
      </vs-alert>
    </div>
    <vx-card
      class="mb-4"
      :title="id === null ? $t('email_template_create') : $t('email_template_edit')"
    >
      <div class="vx-row mb-6 gap-2 items-end justify-start">
        <div class="vx-col flex-grow">
          <vs-select class="w-full" :label="$t('tipo')" v-model="emailTemplateTypeId" @input="typeChanged">
            <vs-select-item
              :value="null"
              text="Nenhum"
            />
            <vs-select-item
              v-for="(type) in emailTypesSelectItems"
              :key="type.value"
              :value="type.value"
              :text="type.text"
            />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('template_type_id')">{{
            errors.first('template_type_id')
          }}</span>
        </div>
        <div class="vx-col pb-2" v-if="selectedType">
          <vs-checkbox v-model="active"> {{ $t('ativo') }} </vs-checkbox>
          <span class="text-danger text-sm" v-show="errors.has('active')">{{
            errors.first('active')
          }}</span>
        </div>
      </div>

      <div class="vx-row mb-6" v-if="selectedType">
        <div class="vx-col w-full">
          <!-- <label class="text-xs pl-2">Assunto</label> -->
          <vs-input :label="$t('assunto')" class="w-full" v-model="subject"/>
          <span class="text-danger text-sm" v-show="errors.has('subject')">{{
            errors.first('subject')
          }}</span>
        </div>
      </div>

      <div class="vx-row mb-6" v-if="selectedType">
        <div class="vx-col w-full">
          <label class="text-sm pl-1">{{ $t('conteudo') }}</label>
          <complex-editor
            v-bind:editor_data.sync="content"
          />
          <span class="text-danger text-sm" v-show="errors.has('content')">{{
            errors.first('content')
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button
            class="float-right"
            color="primary"
            type="border"
            @click="cancel"
            >{{ $t('action.cancel') }}</vs-button
          >
          <vs-button
            v-permission="'email_templates.create'"
            :disabled="!validateForm"
            class="float-right mr-2"
            @click="create"
            >{{ $t('action.save') }}</vs-button
          >
        </div>
      </div>
    </vx-card>
    <vx-card :title="$t('informacoes-do-tipo-de-modelo-selecionado')" class="w-full mb-4" v-if="typeInformation">
      <vs-list>
        <vs-list-item
          :key="key"
          v-for="(information, key) in typeInformation"
          :title="`{${key}}`"
          :subtitle="information"
        />
      </vs-list>
    </vx-card>
  </div>
</template>

<script>
import EmailTemplateService from '@/services/api/EmailTemplateService'
import ComplexEditor from '../questionnaires/elements/ComplexEditor.vue'

export default {

  components: {
    ComplexEditor
  },

  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    id: null,
    emailTemplateTypeId: null,
    subject: '',
    content: '',
    active: true,
    service: null,
    availableEmailTypes: []
  }),
  computed: {
    validateForm() {
      return (
        !this.isEmpty(this.subject) &&
        !this.isEmpty(this.content) &&
        !this.isEmpty(this.emailTemplateTypeId) &&
        !this.typeUnavailable
      )
    },
    emailTypesSelectItems() {
      return this.availableEmailTypes.map((type) => {
        return {
          value: type.id,
          text: this.$t(`email_template_types.${type.name}`)
        }
      })
    },
    selectedType() {
      return !this.isEmpty(this.emailTemplateTypeId)
    },
    typeInformation() {
      if (this.emailTemplateTypeId) {
        const foundType = this.availableEmailTypes.find((type) => {
          return type.id === this.emailTemplateTypeId
        })

        if (foundType && 'info' in foundType) {
          return foundType.info
        }
      }

      return null
    },
    typeUnavailable() {
      if (this.emailTemplateTypeId) {
        const foundType = this.availableEmailTypes.find((type) => {
          return type.id === this.emailTemplateTypeId
        })

        const currentlyUnavailable = ['reset_password']
        
        return (
          foundType && currentlyUnavailable.includes(foundType.name)
        ) || false
      }

      return false
    }
  },
  methods: {
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      this.service
        .createOrUpdate({
          id: this.id,
          email_template_type_id: this.emailTemplateTypeId,
          subject: JSON.stringify(this.subject),
          content: JSON.stringify(this.content),
          active: this.active
        })
        .then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.$router.push(`/email_templates/${data.id}/edit`)
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        )
    },
    cancel() {
      this.$router.push('/email_templates')
    },
    typeChanged(id) {
      const type = this.availableEmailTypes.find((type) => type.id === id)
      if (type) {
        this.content = type.content
        this.subject = type.subject
      }
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        data => {
          this.$set(this.$data, 'id', data.id)
          this.$set(this.$data, 'emailTemplateTypeId', data.email_template_type_id)
          this.$set(this.$data, 'subject', JSON.parse(data.subject))
          this.$set(this.$data, 'content', JSON.parse(data.content))
          this.$set(this.$data, 'active', data.active)
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    }
  },
  beforeMount() {
    this.service = EmailTemplateService.build(this.$vs)
    if (this.id) {
      this.getData(this.id)
    }
    this.service.getEmailTypes().then(
      data => {
        this.availableEmailTypes = data
      }
    )
  }
}
</script>
